// note about svg filters
// https://codepen.io/sosuke/pen/Pjoqqp
// colorize svg used as background-image - original svg needs to be black

// Note: structure of the svg must be
// <svg fill="none">
//   <path d="..." fill="black"/>
// </svg>

// ------------------------------------------------------------------------------------------------
// global root vars

// note: would be nice, but it just doesnt work to grab these values from inside :root fields
// let's keep it here just for reference to copy/paste
// $svg-dark: invert(0%) sepia(0%) saturate(18%) hue-rotate(293deg) brightness(102%) contrast(105%); // black
// $svg-light: invert(100%) sepia(0%) saturate(7500%) hue-rotate(152deg) brightness(101%) contrast(105%); // #fff
// $svg-gold: invert(82%) sepia(25%) saturate(5881%) hue-rotate(359deg) brightness(101%) contrast(102%); // #ffb900;

:root {
  // ---------------------------------------------------
  //  layout

  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
  --safe-area-inset-right: env(safe-area-inset-right);

  --app-max-width: 100%;

  --top-navbar-height: 76px;
  --bottom-navbar-height: 64px;

  --ai-assistant-width: 100%;
  --ai-assistant-height: 100%;

  --desktop-window-max-width: 378px;
  --desktop-toast-max-width: 400px;

  --desktop-sidebar-width: 400px;
  --desktop-sidebar-height: calc(100svh - var(--desktop-navbar-height));

  --desktop-navbar-height: 64px;
  --desktop-game-height: calc(100svh - 64px - 118px); // in feed, game has some space on bottom that reveals next slide
  --desktop-game-height-full: calc(100svh - 64px); // game arrives to the bottom of the screen (ie, in ai-assistant)

  --padding-info-overlay-x: 16px;
  --padding-info-overlay-y: 16px;

  --box-shadow-window: 2px 2px 5px rgba(0, 0, 0, 0.3);
  --box-shadow-navbar: -2px 0px 5px rgba(0, 0, 0, 0.3);
  --box-shadow-assistant: 0px -2px 5px rgba(0, 0, 0, 0.3);

  // ---------------------------------------------------
  //  others

  --title-font-size: 32px; // 40px; // -> requested to make it smaller

  --blur-amount: 14px;
  --animation-duration: 0.3s;

  // ---------------------------------------------------
  //  colors

  // hardcoded colors
  --color-light-50: rgba(255, 255, 255, 0.5);
  --color-dark-50: rgba(0, 0, 0, 0.5);
  --color-light: #fff;
  --color-dark: #3c3d40;
  --color-gray: #3c3d40;
  --color-gold: #ffb900;
  --color-pink: #fd6f8e;
  --color-green: #4ee219;

  --color-gradientz: linear-gradient(125.83deg, #ffee10 27.33%, #ffb900 86.11%);

  --svg-color-dark: invert(0%) sepia(0%) saturate(18%) hue-rotate(293deg) brightness(102%) contrast(105%); // black
  --svg-color-light: invert(100%) sepia(0%) saturate(7500%) hue-rotate(152deg) brightness(101%) contrast(105%); // #fff
  --svg-color-gold: invert(82%) sepia(25%) saturate(5881%) hue-rotate(359deg) brightness(101%) contrast(102%); // #ffb900;
  --svg-color-red: invert(69%) sepia(62%) saturate(6148%) hue-rotate(328deg) brightness(100%) contrast(102%);

  --svg-color-dark-tertiary: invert(58%) sepia(17%) saturate(0%) hue-rotate(249deg) brightness(94%) contrast(102%); // #909090
}

// ------------------------------------------------------------------------------------------------
// different vars for each platorm -> desktop vs mobile

:root.desktop {
  --app-max-width: unset;
  --padding-info-overlay-x: 24px;
  --padding-info-overlay-y: 28px;

  --ai-assistant-width: 400px;
  --ai-assistant-height: calc(100svh - var(--desktop-navbar-height));
}

:root.mobile {
  --app-max-width: 100%;
  --padding-info-overlay-x: 16px;
  --padding-info-overlay-y: 8px;
}

// ------------------------------------------------------------------------------------------------
// different vars for each color scheme -> dark / light

:root.light {
  // light bg colors in light mode
  --bg-color-body: white;
  --bg-color-body-inverse: black;

  --navbar-color: rgba(255, 255, 255, 0.95); // rgba(255, 255, 255, 0.75);
  --navbar-color-transparent: rgba(255, 255, 255, 0.65); // used in currencies
  --separator-color: rgba(0, 0, 0, 0.25);

  // dark svg icons in light mode
  --svg-color: invert(0%) sepia(0%) saturate(18%) hue-rotate(293deg) brightness(102%) contrast(105%);

  // for svgs whose fills are white instead of black
  --svg-inv-color: invert(100%) sepia(0%) saturate(7500%) hue-rotate(152deg) brightness(101%) contrast(105%);

  --bg-color: #eee;
  --fill-color: #e6e6ea; //  #f4f5fb; -> needs to be slightly darker than in figma
  --fill-color-50: #e6e6ea99; //  #f4f5fb99; -> needs to be slightly darker than in figma

  --bg-color-50: rgba(255, 255, 255, 0.5);

  --text-color: #242424;
  --text-color-75: rgba(0, 0, 0, 0.75);
  --text-color-50: rgba(0, 0, 0, 0.5);
  --text-color-25: rgba(0, 0, 0, 0.25);

  --text-color-inverse: white;
}

:root.dark {
  // dark bg colors in dark mode
  --bg-color-body: black;
  --bg-color-body-inverse: white;

  --navbar-color: rgba(0, 0, 0, 0.95); // rgba(0, 0, 0, 0.75);
  --navbar-color-transparent: rgba(0, 0, 0, 0.65); // used in currencies
  --separator-color: rgba(255, 255, 255, 0.25);

  // light svg icons in dark mode
  --svg-color: invert(100%) sepia(0%) saturate(7500%) hue-rotate(152deg) brightness(101%) contrast(105%);

  // for svgs whose fills are white instead of black
  --svg-inv-color: invert(0%) sepia(0%) saturate(18%) hue-rotate(293deg) brightness(102%) contrast(105%);

  --bg-color: #212121;
  --fill-color: #2f2f2f;
  --fill-color-50: #2f2f2f99;

  --bg-color-50: rgba(0, 0, 0, 0.5);

  --text-color: white;
  --text-color-75: rgba(255, 255, 255, 0.75);
  --text-color-50: rgba(255, 255, 255, 0.5);
  --text-color-25: rgba(255, 255, 255, 0.25);

  --text-color-inverse: #242424;
}

// ------------------------------------------------------------------------------------------------

// apply shadow to svg by adding a css classname

.svg-shadow {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
}

.svg-shadow-light {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
}

// ------------------------------------------------------------------------------------------------
