#pwa-permissions-screen {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000000;

  &.hide {
    display: none;
  }
}
