$padding: 12px;
$toast-height: 100px;
$toast-image-width: 50px;

:root.desktop {
  .toast-container {
    max-width: calc(var(--modal-max-width) - 12px);
    max-width: var(--desktop-toast-max-width); // -> this will limit notification toast width on desktop;
  }
}

.toast-container {
  position: absolute;
  z-index: 99997; // note: notification toasts are on the highest possible layer, more than modals and currency slots, always tappable
  right: 0;
  top: -$toast-height;
  width: 100%;
  height: $toast-height;
  padding: $padding; // 15px;
  box-sizing: border-box;

  transition: top 0.5s ease;

  &.show {
    top: env(safe-area-inset-top);
  }

  .toast {
    background-color: var(--navbar-color);
    -webkit-backdrop-filter: blur(var(--blur-amount));
    backdrop-filter: blur(var(--blur-amount));

    border: 1px solid var(--fill-color);
    box-shadow: var(--box-shadow-window);

    border-radius: 16px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 15px;

    color: var(--text-color);
    font-family: 'Pangram Medium';
    font-size: 20px;
    // text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px; // 5px;

    .toast-images {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;

      width: $toast-image-width;
      height: $toast-image-width;

      .toast-round-picture {
        border-radius: 100%;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-size: contain;

        position: absolute;
        right: 0;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));

        &:nth-child(1) {
          z-index: 3;
        }

        &:nth-child(2) {
          right: 5px;
          z-index: 2;
        }

        &:nth-child(3) {
          right: 10px;
          z-index: 1;
        }
      }

      img {
        width: 100%;
      }
    }

    .toast-info {
      display: flex;
      flex-direction: column;
      flex: 1;
      font-size: 14px;
      justify-content: center;

      text-align: left;

      .title {
        font-family: 'Pangram Bold';
        font-size: 22px;
      }

      .title {
        font-family: 'Pangram Bold';
        font-size: 18px;
        padding-top: 5px;
        padding-bottom: 3px;
      }

      .description {
        font-family: 'Pangram Medium';
        font-size: 16px;

        .white {
          color: var(--text-color);
        }
        .gray {
          color: var(--text-color-75);
        }
        .gold {
          color: var(--color-gold);
        }
      }
    }
  }
}
